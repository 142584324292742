import React from "react";
import {
  SEO,
  PageLayout,
  Section,
  BackgroundImages,
  FishermanHeader,
  MarkdownContent,
  BlogPostNavigation,
} from "@bluefin/components";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class BlogPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout className={"blog-page"} hero={false}>
          <Section className={"default-hero-section"}>
            <BackgroundImages
              images={getComponentFiles({
                components: fishermanBusinessWebsitePage.components,
                componentName: "HeroElement",
              })}
              carouselProps={{
                leftArrowIcon: "arrow-left",
                rightArrowIcon: "arrow-right",
              }}
              imageProps={{ loadStrategy: "eager" }}
            >
              <div className={"content-container"}>
                <FishermanHeader
                  as={"h1"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "blog_hero_primary_header",
                    defaultValue: "Blog",
                  })}
                />
                <MarkdownContent
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "blog_hero_description",
                  })}
                />
                <div className={"ctas-container"} />
              </div>
            </BackgroundImages>
          </Section>
          <Section className={"blog-cards-section"} textAlign={"center"}>
            <BlogPostNavigation
              title={""}
              description={""}
              featuredPostId={null}
              alwaysShowSearch={false}
              columns={3}
              pageSize={12}
              tight={true}
              paginatedGridProps={{
                pagination: {
                  infiniteScroll: {
                    enabled: true,
                    showMoreButtonProps: { primary: true },
                  },
                },
              }}
              posts={allFishermanBusinessBlogPost.nodes}
            />
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Blog" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessBlogPost(filter: { destination: { eq: "BLOG" } }) {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
        heroImageAltText
      }
    }
  }
`;
